.title {
  color: #fff !important;
}
.text {
  color: #fff !important;
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
}

.textBig {
  font-size: 1.5rem;
}

.imageBox {
  padding: 40px;
}

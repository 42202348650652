.base {
  position: relative;
  height: auto;
  z-index: 1;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #4f5969;
  box-shadow: 0 1px 10px rgb(151 164 175 / 10%);
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 66px;
}

.col {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .base {
    padding: 0 15px !important;
  }

  .logo {
    max-width: 110px;
  }
}

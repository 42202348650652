.base {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  /* width: 100%; */
  margin-left: 20px;
  justify-content: flex-start;
  border-bottom: 0px;
}

.item {
  padding: 0px 10px;
}

.link {
  color: #4f5969;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .base {
    display: none !important;
  }
}

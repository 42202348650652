.base {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
}

.pullup {
  margin-top: -200px;
}

@media (min-width: 576px) {
  .base {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .base {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .base {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .base {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .base {
    max-width: 1320px;
  }
}

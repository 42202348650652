.base {
  padding: 35px 0px;
}
.intro {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  min-height: 48vh;
  background-color: #eee;
  background-repeat: no-repeat;
}

.defi {
  min-height: 48vh;
  background-color: #4f5969;
  color: #fff;
  padding-bottom: 150px;
}

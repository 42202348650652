.base {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
}

.primary {
  background-color: #4f5969;
  color: #fff;
  width: 100%;
  border-radius: 40px;
  padding: 10px 15px;
}

.secondary {
  background-color: #17d89b;
  color: #fff;
  border-radius: 5px;
  padding: 4px 15px;
  margin: 5px 0px;
}

.slim {
  padding: 5px 15px;
}

.base {
  padding: 20px 20px 40px 20px;
  background-color: #f8f6f6;
}

.title {
  font-size: 1.2rem;
  margin: 0 7.5px;
}

.tagNew {
  background-color: #f58484;
  color: #fff;
  font-size: 1rem;
  padding: 10px 25px;
  border-radius: 5px;
  margin: 0 7.5px 0 0;
}

.tagDefault {
  background-color: #e7e7e7;
  color: #f8f6f6;
  font-size: 1rem;
  padding: 10px 25px;
  border-radius: 5px;
  margin: 0 7.5px;
}

.image {
  background-repeat: no repeat;
  background-size: cover;
  background-position: center;
  min-height: 200px;
}

.textNumber {
  font-size: 3.2rem;
  line-height: 2.2rem;
}
.tag {
  font-size: 1.2rem;
}
.text {
  display: block;
  font-size: 1.2rem;
}
.box {
  display: flex;
  align-items: center;
}
.iconInfo {
  display: inline;
  margin-bottom: 15px;
  margin-left: 8px;
}

@media screen and (max-width: 575px) {
  .title {
    font-size: 0.85rem;
  }
  .tagDefault {
    font-size: 0.75rem;
    padding: 5px 10px;
  }
  .tagNew {
    font-size: 0.75rem;
    padding: 5px 10px;
  }
}

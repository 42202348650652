.title {
  color: #4f5969 !important;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}
.subtitle {
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  margin-bottom: 0px !important;
  line-height: 1.2rem !important;
}
.text {
  display: block;
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 15px;
}
.textRegular {
  font-size: 1.2rem;
}
.textBold {
  font-weight: bold;
}
.textBig {
  font-size: 2rem;
  /* font-weight: bold; */
}
.DescriptionBox {
  max-width: 500px;
}
.listItem {
  margin-bottom: 20px;
}
.listItemText {
  margin-bottom: 0px;
  font-size: 1rem;
  line-height: 1.3rem;
}
.iconBox {
  min-width: 70px;
  margin: 0 auto;
}
.iconBox img {
  display: block;
  margin: 0 auto;
}

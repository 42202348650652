.base {
  background-color: #303030;
  padding: 50px 0px;
}
.title {
  color: #fff !important;
}
.textCenter {
  text-align: center !important;
}
.intro {
  color: #fff !important;
  font-size: 1.3rem;
  display: block;
  margin-bottom: 30px;
}
.text {
  color: #fff !important;
}
.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  width: 60%;
  border-radius: 40px;
  padding: 5px 15px;
  margin: 30px auto;
  display: inline-block;
}

.list {
  color: #fff !important;
  list-style: none;
  padding-left: 0px;
}
.listItem {
  color: #fff !important;
}
.listLink {
  color: #fff !important;
}

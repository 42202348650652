.body {
  padding: 0px 0px 0px 60px;
  margin: 0 0 0 auto;
  max-width: 475px;
}

.image {
  padding: 0px;
}

.button {
  margin-top: 30px;
  width: 80%;
}
.button a {
  font-size: 0.9rem;
  padding: 4px;
}
@media screen and (max-width: 991px) {
  .body {
    margin: 0 auto;
    padding: 0px 30px 0px 30px;
  }

  .image {
    margin: 30px 0 0 0;
  }
}

@media screen and (max-width: 575px) {
  .body {
    padding: 0px;
  }
  .image {
    padding: 30px 0 0 0;
  }
}

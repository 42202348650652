.title {
  color: #fff !important;
}
.title2 {
  color: #4f5969 !important;
}
.text {
  color: #000 !important;
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
}
.textMid {
  font-size: 1.2rem;
}
.textGray {
  font-size: 1.1rem;
  color: #989898 !important;
}
.textBig {
  font-size: 1.5rem;
}
.card {
  background-color: #f8f6f6;
  padding: 15px 30px 30px;
}
.cardHeader {
  margin: 15px 0px 0px;
}
.cardBody {
  margin: 20px 0;
}
.icon {
}
